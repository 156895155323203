.dashboard{
  .ibox-content {
    margin: 0px 0;
  }
  .table-courses button{
    border: none;
    background: none;
    padding: 0;
  }
  .table-courses button:focus {
    outline: none !important;
  }
  .view-more{
    margin-top: 10px;
  }
  .marged{
    margin-top: 15px;
  }
}

//calender
