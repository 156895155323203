.i-checks {
    label {
        margin-left: 15px;
        padding-top: 5px;
    }
}

.ibox-title {
    h3 {
        font-size: 20px !important;
    }
}

.ibox-content {
    margin: 15px 0;
    h3 {
        font-size: 18px;
    }
    .form-horizontal .control-label {
        font-size: 15px;
        font-weight: 500;
    }
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance:textfield;
}
.page-heading {
    margin-top: 15px;
}

.req {
    color: #b00;
}

.text-left {
    text-align: left;
}

.float-e-margins .btn {
    margin-bottom: 5px;
    padding: 8px 25px;
    font-size: 18px;
}

.checked #terms-error {
    display: none;
}

.visib-hidden{
  visibility: hidden;
  height: 0;
}
input.checkbox-custom{
  position: absolute;
  opacity: 0;
}
.getEmbed{
  float: right;
  margin: 33px 0;
  font-size: 20px;
}
.helper-style{
  position: absolute;
  top: 0%;
  left: 0%;
  display: block;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  background: rgb(255, 255, 255);
  border: 0px;
  opacity: 0;
}
/* Always set the map height explicitly to define the size of the div
       * element that contains the map. */
.text-right-custom{
  text-align: right;
}
#map {
    height: 100%;
    min-height: 500px;
}
.visib-hidden #map{
  height: 0;
}
.ui-datepicker table{
    background:white !important;
}
.ui-state-active{
    background:#18A689 !important;
    color: white !important;
}
.ui-state-disabled{
  .ui-state-default{
    background: #d3d3d3;
    color: darkgrey;
  }
}
/* Optional: Makes the sample page fill the window. */

html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}

.datepicker{
    position: relative;
    z-index: 9999;
}

.terms label {
    padding-top: 0 !important;
}

@media (max-width: 768px) {
    .i-checks label {
        padding-top: 0px !important;
    }
}
@media only screen and (max-width : 480px) {
  .text-right-custom{
    text-align: left;
  }
}
// ahmars changes
strong {
    &.normal {
        text-decoration: none;
        color : #2f4050;
    }
}
